@import url('https://fonts.googleapis.com/css?family=Open+Sans');


:root {
  --primary: #C01828;
  --secondary: #E7E0EB;
  --green: #A5D693;
  --yellow: #F4C800;
  --orange: #F58C00;
  --red: #F53B00;
  --blue: #EBBAF5;
}

.bg-primary {
  background-color: var(--primary);

}

.bg-secondary {
  background-color: var(--secondary);
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities